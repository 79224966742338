import { useReportStore } from "../data/ReportStore"

export type Team = {
  abbrev: string,
  city: string,
  name: string,
  logo_src: string,
}

export function getTeamFromString(abbrev: string): Team {
  let teams = useReportStore.getState().teams

  if (!teams) throw new Error("No teams found")
  const team = teams.find((t) => t.abbrev === abbrev)
  if (!team) throw new Error("Error finding team: invalid team abbreviation")
  return team
}
