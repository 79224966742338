import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from "@mui/material";
import "../../Elements/DataTable/dataTable.scss";
import DataTableSkeleton from "../../Elements/DataTable/DataTableSkeleton";

interface CrashingTableProps {
  reportCard?: Array<Record<string, any>>;
  tableType?: string;
}

const columnOrders: Record<string, Array<string>> = {
  crashing: ["OffensiveChancePlayer", "CrashOpportunities", "CrashRateAdj"],
  ezpts: ["EZPts", "EZPtsRate", "EZPtsPer100"],
  three_detail: [
    "Title",
    "3FGM",
    "3FGA",
    "3FGA_Ratio",
    "QSQ",
    "3P%",
  ],
  three_log: [
    "PERIOD",
    "GAME_CLOCK",
    "SHOT_CLOCK",
    "Shooter",
    "TRANSITION",
    "REGION",
    "DISTANCE",
    "CATCH_AND_SHOOT",
    "CONTEST_LEVEL",
    "QSQ",
    "OUTCOME",
  ],
};

const columnLabel: Record<string, Record<string, string>> = {
  crashing: {
    OffensiveChancePlayer: "Crasher",
    CrashOpportunities: "FGA",
    CrashRateAdj: "Crash Rate",
  },
  ezpts: {
    EZPts: "EZ Pts",
    EZPtsRate: "EZ Pts Rate",
    EZPtsPer100: "EZ Pts / 100",
  },
  three_detail: {
    "Title": "",
    "3FGM": "3FGM",
    "3FGA": "3FGA",
    "3P%": "3P%",
    "QSQ": "e3P%",
    "3FGA_Ratio": "% of Att",
  },
  three_log: {
    PERIOD: "Period",
    GAME_CLOCK: "Time",
    SHOT_CLOCK: "Shot Clock",
    Shooter: "Shooter",
    TRANSITION: "Transition",
    REGION: "Region",
    DISTANCE: "Distance",
    CATCH_AND_SHOOT: "Setup",
    CONTEST_LEVEL: "Contest Leve",
    QSQ: "e3P%",
    OUTCOME: "Outcome",
  },
};

const CrashingTable: React.FC<CrashingTableProps> = ({ reportCard, tableType = "crashing" }) => {
  const columnOrder = columnOrders[tableType];
  const columnLabels = columnLabel[tableType];

  if (reportCard === undefined) {
    return <DataTableSkeleton />;
  }

  if (reportCard.length === 0) {
    return <Alert severity="info">There is no data</Alert>;
  }

  return (
    <TableContainer component={Paper} sx={{ mb: 4, width: "fit-content" }}>
      <Table className="data-table simple">
        <TableHead>
          <TableRow>
            {columnOrder.map((column) => (
              <TableCell key={column} sx={{ width: "auto !important" }}>
                {columnLabels[column]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reportCard.map((row, index) => (
            <TableRow key={row.id || row.key || index}>
              {columnOrder.map((column) => (
                <TableCell
                  key={column}
                  sx={{
                    color:
                      row[column] === "make"
                        ? "green"
                        : row[column] === "miss"
                        ? "#cf0a2c"
                        : "auto",
                  }}
                >
                  {row[column]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CrashingTable;
