import create from "zustand";
import { ReportType } from "../models/Reports";
import { Team } from "../models/Team";
import { getTeamsInfo } from "../util/Requests";

interface ReportState {
  team: Team | null;
  teams: Team[];
  initTeams: () => Promise<void>;
  setTeam: (t: Team | null) => void;
  setReportType: (r: ReportType | null) => void;
  reportType: ReportType | null;
}

export const useReportStore = create<ReportState>((set) => ({
  team: null,
  teams: [],
  initTeams: async () => {
    const res = await getTeamsInfo()
    const teams = res.data
    const newteams = teams.map((t: Team) => {
      return Object.fromEntries(Object.entries(t).map(([k, v]) => [k.toLocaleLowerCase(), v]))
    })
    set(({ teams: newteams }))
  },
  setTeam: (team: Team | null) => set((state) => ({ team })),
  reportType: null,
  setReportType: (reportType: ReportType | null) =>
    set((state) => ({ reportType })),
}));
