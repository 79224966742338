import "./gameDisplay.scss";
import { Stack, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { GameWithID } from "../../../models/Game";
import GameDisplay from "./GameDisplay";

interface Props {
  game: GameWithID;
}

export default function PastGame({ game }: Props) {
  return (
    <Stack direction="row" className="game-display--container past">
      <GameDisplay game={game} />
      <Box className="game-display--button-container">
        <Button
          component={Link}
          to={`/games/${game.id}`}
          className="game-display--report-link"
          disabled={!game.reportAvailable}
        >
          {game.reportAvailable ? "Game Report" : "Data missing"}
        </Button>
      </Box>
    </Stack>
  );
}
