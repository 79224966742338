import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import { GoalGroup } from "../../../models/IdReport";
import { convertPlayerName } from "../../../util/strings";
import GoalDisplay from "./Goal";
import { QueryStatus } from "../../../data/ExplorerStore";

export default function GoalGroupDisplay({
  goalGroup,
  reportFS,
  expand,
  expand2,
}: {
  goalGroup: GoalGroup;
  reportFS: QueryStatus;
  expand?: boolean;
  expand2?: boolean;
}) {
  const isTeamGoalGroup = !goalGroup.nba_id;
  return (
    <Box
      mt={goalGroup.title === "Transition Offense" ? 6 : 6}
      mb={goalGroup.title === "Transition Offense" ? 6 : 0}
      sx={{ width: isTeamGoalGroup ? "48%" : expand || expand2 ? "49.5%" : "auto" }}
      ml={isTeamGoalGroup && expand2 ? 4 : 0}
    >
      {isTeamGoalGroup && <TeamGoalGroupHeader goalGroup={goalGroup} />}
      <Grid container spacing={2} ml={!isTeamGoalGroup && expand2 ? 2 : 0}>
        {!isTeamGoalGroup && <PlayerGoalGroupHeader goalGroup={goalGroup} expand={expand2} />}
        {goalGroup.goals.map((goal) => (
          <GoalDisplay
            goal={goal}
            isTeamGoalGroup={isTeamGoalGroup}
            reportFS={reportFS}
            key={goal.name}
            expand={expand}
            expand2={expand2}
          />
        ))}
      </Grid>
    </Box>
  );
}

function TeamGoalGroupHeader({ goalGroup }: { goalGroup: GoalGroup }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1.5} mb={2} ml={2.2}>
      <img
        src="/images/blazers-logo-black.png"
        alt="Trail Blazers Logo"
        height="43px"
        width="auto"
      />
      <Typography variant="h5" textTransform="uppercase" fontSize="20px">
        {goalGroup.title}
      </Typography>
    </Stack>
  );
}

function PlayerGoalGroupHeader({ goalGroup, expand }: { goalGroup: GoalGroup, expand?: boolean }) {
  const headshotSrc = `https://cdn.nba.com/headshots/nba/latest/1040x760/${goalGroup.nba_id}.png`;
  return (
    <Grid item ml={expand ? 0 : 0}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={1.5}
        py={2}
        px={1}
        className="id-report--player-header-container"
      >
        <Avatar
          src={headshotSrc}
          className="id-report--player-headshot"
          sx={{ height: "34px", width: "34px" }}
        ></Avatar>
        <Typography
          variant="h5"
          textTransform="uppercase"
          className="id-report--player-header"
        >
          {convertPlayerName(goalGroup.title)}
        </Typography>
      </Stack>
    </Grid>
  );
}
