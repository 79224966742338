import "./identityReport.scss";
import {
  Box,
  Container,
  Typography,
  Stack,
  Grid,
  Skeleton,
  CircularProgress,
  FormControl,
  InputBase,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { getIdReport, getIdReportList } from "../../../util/Requests";
import { IdReport } from "../../../models/IdReport";
import GoalGroupDisplay from "./GoalGroup";
import { StyledRadioButtonsGroup } from "../../Elements/StyledRadioButtons/StyledRadioButtonsGroup";
import { StyledRadioButton } from "../../Elements/StyledRadioButtons/StyledRadioButton";
import { QueryStatus } from "../../../data/ExplorerStore";
import CrashGoal, { TeamCrashGoal } from "./CrashGoal";

const REPORT_RANGES = ["season", "l5", "l10", "l20"];

export default function IdentityReport() {
  const [report, setReport] = useState<IdReport | null>(null);
  const [reportList, setReportList] = useState<[string, string][]>([]);
  const [reportListFS, setReportListFS] = useState<QueryStatus>(QueryStatus.None);
  const [reportRange, setReportRange] = useState<
    "season" | "l5" | "l10" | "l20" | string | undefined
  >(undefined);

  const selectedReport = reportList.find((r) => r[0] === reportRange);

  const gameReports = useMemo(
    () => reportList.filter((r) => !REPORT_RANGES.includes(r[0])),
    [reportList]
  );
  const rangeReports = useMemo(
    () => reportList.filter((r) => REPORT_RANGES.includes(r[0])),
    [reportList]
  );

  const isGameReport = (reportId?: string) => reportId ? !REPORT_RANGES.includes(reportId) : false;

  console.log("gameReports: ", gameReports);

  const teamCrashGoal = report?.offensiveRebounding.goals.find(
    (g) => g.name === "Team"
  );
  const indCrashGoals =
    report?.offensiveRebounding.goals.filter((g) => g.name !== "Team") ?? [];
  indCrashGoals.sort((g1, g2) =>
    parseInt(g1.sampleSize) > parseInt(g2.sampleSize) ? -1 : 1
  );

  const [reportFS, setReportFS] = useState<QueryStatus>(QueryStatus.None);
  const loading = reportFS === QueryStatus.Requesting;

  useEffect(() => {
    setReportListFS(QueryStatus.Requesting);
    getIdReportList().then((res) => {
      setReportListFS(QueryStatus.Complete);
      // TEMP - sort so that Season is the first in the list
      setReportList(
        res.data.sort((a: any, b: any) => (a[0] === "season" ? -1 : 1))
      );
    });
  }, []);

  useEffect(() => {
    if (gameReports.length > 0) {
      setReportRange(gameReports[0][0]);
    }
  }, [gameReports]);

  useEffect(() => {
    if (!reportRange) {
      return
    }
    setReportFS(QueryStatus.Requesting);
    getIdReport(reportRange).then((res) => {
      setReportFS(QueryStatus.Complete);
      setReport(res.data);
    });
  }, [reportRange]);

  return (
    <Container maxWidth="xl" sx={{ overflow: "hidden", pb: 4 }}>
      <Typography className="selected-report-title" variant="h4">
        {selectedReport ? selectedReport[1] : ""}
      </Typography>
      <Typography variant="h2" className="report-title">
        Identity Report
      </Typography>

      <StyledRadioButtonsGroup
        id="reportRange"
        value={reportRange}
        onChange={(event, value) => setReportRange(value)}
        row={true}
        className="report-select"
      >
        {gameReports.length > 0 && (
          <FormControl
            variant="standard"
            className="id-report-select--container"
          >
            <Select
              value={isGameReport(reportRange) ? reportRange : "select"}
              disabled={loading}
              onChange={(e) => {
                if (isGameReport(e.target.value)) {
                  setReportRange(e.target.value);
                }
              }}
              input={
                <InputBase
                  className={`id-report-select--input-base${
                    isGameReport(reportRange)
                      ? " selected"
                      : loading
                      ? " disabled"
                      : ""
                  }`}
                />
              }
            >
              <MenuItem value={"select"} disabled>
                Select game
              </MenuItem>
              {gameReports.map(([id, label]) => (
                <MenuItem value={id}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {rangeReports.map(([id, label]) => (
          <StyledRadioButton
            label={label}
            value={id}
            textSize="sm"
            selected={id === reportRange}
            key={id}
            disabled={loading}
          />
        ))}
        {reportList.length === 0 && reportListFS !== QueryStatus.Complete &&(
          <Skeleton width="100px" variant="rounded"></Skeleton>
        )}
      </StyledRadioButtonsGroup>
      {!report ? (
        <Box
          sx={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {reportListFS === QueryStatus.Complete && reportList.length === 0 ? (
            <Typography variant="h4">No reports available</Typography>
          ) : (
            <CircularProgress size={75} thickness={5} />
          )}
        </Box>
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={9.8} xl={8}>
              <Stack direction="column">
                {/* !! WARNING: Very hacky code below to align goal groups on the same line. Prone to breakage !! */}
                {report.goalGroups.map((goalGroup, i) => {
                  if (i === 0)
                    return (
                      <Stack direction="row">
                        <GoalGroupDisplay
                          goalGroup={goalGroup}
                          reportFS={reportFS}
                          key={goalGroup.title}
                          expand
                        />
                        <GoalGroupDisplay
                          goalGroup={report.goalGroups[1]}
                          reportFS={reportFS}
                          key={report.goalGroups[1].title}
                          expand2
                        />
                      </Stack>
                    );
                  if (i > 1 && i < 7)
                    return (
                      <GoalGroupDisplay
                        goalGroup={goalGroup}
                        reportFS={reportFS}
                        key={goalGroup.title}
                      />
                    );

                  if (i === 7)
                    return (
                      <Stack direction="row">
                        <GoalGroupDisplay
                          goalGroup={goalGroup}
                          reportFS={reportFS}
                          key={goalGroup.title}
                          expand
                        />
                        <GoalGroupDisplay
                          goalGroup={report.goalGroups[8]}
                          reportFS={reportFS}
                          key={report.goalGroups[8].title}
                          expand
                          expand2
                        />
                      </Stack>
                    );

                  return null;
                })}
              </Stack>
            </Grid>
            <Grid item xs={2.2}>
              <Box>
                <Typography
                  variant="h5"
                  textTransform="uppercase"
                  fontSize="20px"
                  mt={7.5}
                  mb={2}
                >
                  Offensive Rebounding
                </Typography>
                <Typography
                  variant="h5"
                  className="crash-category--header"
                  fontSize="18px"
                  fontWeight="medium"
                >
                  Team
                </Typography>
                <Typography className="crash-category--subtitle">
                  Multiple crashers on 3PA
                </Typography>
                <TeamCrashGoal goal={teamCrashGoal} loading={loading} />
                <Typography
                  variant="h5"
                  className="crash-category--header"
                  fontSize="18px"
                  fontWeight="medium"
                >
                  Individual
                </Typography>
                <Typography className="crash-category--subtitle">
                  Crash rate on 3PA
                </Typography>
                {indCrashGoals.map((g) => (
                  <CrashGoal
                    goal={g}
                    key={`${g.name}:crashing`}
                    loading={loading}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
}
