export enum ReportType {
  Offense = "offense",
  Defense = "defense",
}

export interface UnparsedReport {
  season: number;
  report: string;
}

export interface Report {
  season: number;
  report: {
    [s: string]: Array<ReportCardSection>
  }
}

type ReportCardGroup = {
  header: string;
  reportCards: ReportCardLabel[];
};

interface ReportCardLabel {
  label?: string;
  key: string;
  showSectionHeaders?: boolean;
}

export function isReportCard(data: any): data is Array<ReportCardSection> {
  return (
    Array.isArray(data) && data.length > 0 && Object.hasOwn(data[0], "headers")
  );
}

export const teamReportCards: { [k: string]: ReportCardGroup[] } = {
  offense: [
    { header: "Overview", reportCards: [{ key: "overall" }] },
    {
      header: "Pick & Roll",
      reportCards: [
        { key: "77", label: "77" },
        { key: "MPR", label: "MPR" },
        { key: "SPR", label: "SPR" },
        { key: "step_up", label: "Step up" },
        { key: "corner", label: "Corner" },
      ],
    },
    { header: "Snap PR", reportCards: [{ key: "snap_pr" }] },
    {
      header: "Small Small PR",
      reportCards: [
        { key: "small_small_pr_team", label: "Team" },
        { key: "small_small_pr_players", label: "Players" },
      ],
    },
    {
      header: "Other Offensive Actions",
      reportCards: [
        { key: "dho", label: "DHO" },
        { key: "small_small_dho", label: "Small small DHO" },
        { key: "screening", label: "Screening" },
        { key: "small_small_screening", label: "Small small screening" },
        { key: "iso", label: "Iso" },
        { key: "post", label: "Post" },
      ],
    },
    {
      header: "Special Situations",
      reportCards: [
        { key: "zone", label: "Zone" },
        { key: "foul_to_give", label: "Foul to give" },
        { key: "need_2", label: "Need 2" },
        { key: "foul_up_3_need_3", label: "Foul up 3 need 3" },
        { key: "get_in", label: "Get in" },
      ],
    },
  ],
  defense: [
    { header: "Overview", reportCards: [{ key: "overview" }] },
    { header: "Transition", reportCards: [{ key: "transition" }] },
    {
      header: "Post",
      reportCards: [{ key: "post_players", showSectionHeaders: true }],
    },
    {
      header: "Iso",
      reportCards: [{ key: "iso_players", showSectionHeaders: true }],
    },
    {
      header: "Pick & Roll",
      reportCards: [
        {
          key: "pr_ballhandlers",
          label: "Ballhandlers",
          showSectionHeaders: true,
        },
        { key: "pr_screeners", label: "Screeners", showSectionHeaders: true },
      ],
    },
    {
      header: "Small Small PR",
      reportCards: [{ key: "sm_sm_ballhandlers", showSectionHeaders: true }],
    },
    {
      header: "DHO",
      reportCards: [{ key: "dho_ballhandlers", showSectionHeaders: true }],
    },
    {
      header: "Screening Action",
      reportCards: [{ key: "screening_players", showSectionHeaders: true }],
    },
    {
      header: "Closeouts",
      reportCards: [{ key: "closeout_players", showSectionHeaders: true }],
    },
    {
      header: "Special Situations",
      reportCards: [
        { key: "zone", label: "Zone" },
        { key: "foul_to_give", label: "Foul to give" },
        { key: "need_2", label: "Need 2" },
        { key: "foul_up_3_need_3", label: "Foul up 3 need 3" },
        { key: "get_in", label: "Get in" },
      ],
    },
  ],
};

export function getReportTypeFromString(reportTypeString?: string): ReportType {
  switch (reportTypeString) {
    case ReportType.Offense:
      return ReportType.Offense;
    case ReportType.Defense:
      return ReportType.Defense;
    default:
      throw new Error("invalid report type");
  }
}

export interface ReportCardSection {
  len: number;
  title: string;
  nbaPlayerId?: string;
  headers: Header[];
  splits: SplitsMap;
  rows: Row[];
}
type Header = [title: string, span: number];
type SplitsMap = { [n: number]: string };
type Row = Cell[];
type Cell = [data: string, units: string];
