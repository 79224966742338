import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Pages/Home";
import TeamReport from "./components/Pages/TeamReport/TeamReport";
import Explorer from "./components/Pages/Explorer/Explorer";
import GameLog from "./components/Pages/GameLog";
import NavBar from "./components/Elements/NavBar/NavBar";
import GameReport from "./components/Pages/GameReport/GameReport";
import { Amplify } from "aws-amplify";
import {
  withAuthenticator,
  WithAuthenticatorProps,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import IdentityReport from "./components/Pages/IdentityReport/IdentityReport";
import { useEffect } from "react";
import { useReportStore } from "./data/ReportStore";

Amplify.configure({
  Auth: {
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-west-2_Wgn9i4TEw",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "snvmjfkd4300b55qmou7jgaei",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    signUpVerificationMethod: "code", // 'code' | 'link'
  },
});

function App({ user, signOut }: WithAuthenticatorProps) {
  const { initTeams, teams } = useReportStore((store) => store)
  useEffect(() => {
    if (!teams || teams.length === 0) {
      initTeams()
    }
  }, [teams, initTeams])

  if (!user || !user.attributes) return null;
  // Should have some kind of loading indicator while fetching teams
  if (teams.length === 0) return null;

  const { given_name, family_name } = user.attributes;

  return (
    <div className="App">
      <NavBar
        given_name={given_name}
        family_name={family_name}
        handleSignOut={signOut}
      />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reports" element={<Home />} />
          <Route
            path="/reports/:teamAbbrev/:reportTypeString"
            element={<TeamReport />}
          />
          <Route path="/explorer" element={<Explorer />} />
          <Route path="/games" element={<GameLog />} />
          <Route path="/games/:gameId" element={<GameReport />} />
          <Route path="/id-report" element={<IdentityReport />} />
        </Routes>
      </main>
    </div>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
