import { Box, CircularProgress, Container, MenuItem, Select, Typography } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import ReportCard from "../../Elements/ReportCard/ReportCard";
import { getTeamFromString } from "../../../models/Team";
import { useReportStore } from "../../../data/ReportStore";
import ReportPicker from "../../Elements/ReportPicker";
import { getTeamReport } from "../../../util/Requests";
import {
  getReportTypeFromString,
  isReportCard,
  ReportCardSection,
  teamReportCards,
  Report,
  UnparsedReport,
} from "../../../models/Reports";
import DataTableSkeleton from "../../Elements/DataTable/DataTableSkeleton";
import { isTableData } from "../../../models/TableData";
import DataTable from "../../Elements/DataTable/DataTable";

export default function TeamReport() {
  const [report, setReport] = useState<Report | null>(null);
  const reportData = useMemo(() => {
    if (report === null) return null;
    return report.report;
  }, [report]);
  const [reports, setReports] = useState<Array<Report> | null>(null)
  const { teamAbbrev, reportTypeString } = useParams();
  const { team, setTeam, reportType, setReportType } = useReportStore(
    (state) => state
  );
  const [error, setError] = useState<string>("");

  useEffect(() => {
    try {
      setTeam(getTeamFromString(teamAbbrev?.toLocaleUpperCase() ?? ""));
      setReportType(getReportTypeFromString(reportTypeString));
    } catch (e) {
      console.log(e);
      if (e instanceof Error) setError(e.toString());
    }
  }, [setTeam, setReportType, reportTypeString, teamAbbrev]);

  useEffect(() => {
    setReport(null);
    setReports(null)
    setError("");
    if (!team) {
      setError("Error: Invalid team");
      return;
    } else if (!reportType) {
      setError("Error: Invalid report type");
      return;
    }

    getTeamReport(reportType, team)
      .then((response) => {
        console.log("reports: ", response.data)
        const parsedReports = response.data.map((r: UnparsedReport) => Object.assign(r, {report: JSON.parse(r.report)[reportType]}))
        setReports(parsedReports)
        const firstReport = parsedReports[0]
        setReport(firstReport);
      })
      .catch((err) => {
        console.log(`error fetching report: ${err}`);
        setError(err.message);
      });
  }, [teamAbbrev, reportType, team]);

  const ReportContent = () => {
    return !reportType ? (
      <Container maxWidth="md" sx={{ textAlign: "center", mt: 5 }}>
        <CircularProgress size={75} thickness={5} />
      </Container>
    ) : (
      <>
        {teamReportCards[reportType as string].map((reportCardGroup) => {
          return (
            <React.Fragment
              key={`${teamAbbrev}:${reportType}:${reportCardGroup.header}`}
            >
              <Typography variant="h2" mb={4} textAlign="center">
                {reportCardGroup.header}
              </Typography>
              {reportData !== null ? (
                reportCardGroup.reportCards.map((reportCardLabel) => {
                  const { key, label, showSectionHeaders } = reportCardLabel;
                  const data = reportData[key];
                  const dataDisplay = isReportCard(data) ? (
                    <ReportCard
                      reportCard={data}
                      showSectionHeaders={showSectionHeaders}
                      tableClassName="team-report"
                    />
                  ) : isTableData(data) ? (
                    <DataTable data={data} />
                  ) : (
                    <Typography sx={{ fontSize: "20px" }}>
                      Not enough data
                    </Typography>
                  );

                  return (
                    <Box
                      key={`${team?.abbrev}${reportType}report:${reportCardGroup.header}:${key}`}
                      mb={7}
                    >
                      {!!label && (
                        <Typography
                          variant="h3"
                          className="report-card--subheader"
                          mb={2}
                        >
                          {label}
                        </Typography>
                      )}
                      {dataDisplay}
                    </Box>
                  );
                })
              ) : (
                <>
                  <DataTableSkeleton num={1} />
                  <DataTableSkeleton num={3} />
                  <DataTableSkeleton num={11} />
                </>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <>
      <ReportPicker collapseByDefault />

      <Container maxWidth="xl">
        <Select
          value={report?.season || "select"}
          disabled={reports?.length === 0}
          onChange={(e) => {
            const selectedReport = reports?.find(
              (r) => r.season === e.target.value
            );
            setReport(selectedReport || null);
          }}
        >
          <MenuItem value="select" disabled>
            Select season
          </MenuItem>
          {reports?.map((r) => (
            <MenuItem value={r.season} key={r.season}>
              {r.season}
            </MenuItem>
          ))}
        </Select>
        {!!error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <ReportContent />
        )}
      </Container>
    </>
  );
}
